﻿function ToggleAppointments() {
    var formElement = document.querySelectorAll("#collapseAppointmentDates");
    var ShowAppointments = document.querySelectorAll("#ShowAppointments");
    var HideAppointments = document.querySelectorAll("#HideAppointments");

    formElement.forEach((f) => {
        f.classList.toggle("show-appointments");
    });
    ShowAppointments.forEach((f) => {
        f.classList.toggle("hide-appointments");
    });
    HideAppointments.forEach((f) => {
        f.classList.toggle("hide-appointments");
    });
}

document.querySelectorAll("#ShowAppointments").forEach(el => {
    el.addEventListener("click", ToggleAppointments, false);
});
document.querySelectorAll("#HideAppointments").forEach(el => {
    el.addEventListener("click", ToggleAppointments, false);
});