﻿import 'bootstrap';
import "../scss/style.scss";


import "./_fontawesome.js";


import './components/index.js';
// Custom CSS imports
//import '../css/site.css';

console.log('The \'site\' bundle has been loaded!');