﻿import { library, dom } from "@fortawesome/fontawesome-svg-core";
import {
    faPhoneAlt,
    faMapMarkerAlt,
    faPaperPlane,
    faHeadset,
    faCalendarCheck,
    faPlusCircle,
    faMinusCircle,
    faCircle,
    faBalanceScale,
    faCheckCircle,
    faTimesCircle,
    faCaretRight,
    faHouse,
    faCrutch,
    faHiking,
    faGavel,
    faCoins
} from "@fortawesome/free-solid-svg-icons";
import {
    faCircle as farCicle,
    faCheckCircle as farCheckCircle,
    faTimesCircle as farTimesCircle
} from "@fortawesome/free-regular-svg-icons"
import { faFacebookSquare, faTwitterSquare, faLinkedin, faInstagramSquare } from "@fortawesome/free-brands-svg-icons";

const solidSVG = [
    faPhoneAlt,
    faMapMarkerAlt,
    faPaperPlane,
    faHeadset,
    faCalendarCheck,
    faPlusCircle,
    faMinusCircle,
    faCircle,
    faBalanceScale,
    faCheckCircle,
    faTimesCircle,
    faCaretRight,
    faHouse,
    faCrutch,
    faHiking,
    faGavel,
    faCoins
];

const brandSVG = [
    faFacebookSquare, faTwitterSquare, faLinkedin, faInstagramSquare
];

const regularSVG = [
    farCicle,
    farCheckCircle,
    farTimesCircle
];

library.add(solidSVG);
library.add(brandSVG);
library.add(regularSVG);
dom.watch();